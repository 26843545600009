.pop-up {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($theme-color-1, 0.8);
  z-index: 9999;
  // opacity: 0;
  // visibility: hidden;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  transition: all 0.3s ease-out;

  // @supports (-webkit-backdrop-filter: blur(10px)) or
  //   (backdrop-filter: blur(10px)) {
  //   -webkit-backdrop-filter: blur(10px);
  //   backdrop-filter: blur(10px);
  //   background-color: rgba($theme-color-1, 0.3);
  // }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 115rem;
    // height: 140rem;
    padding: 20rem 200px;
    background-color: #ffff;
    box-shadow: 0 2rem 4rem rgba(#000, 0.2);
    border-radius: 3rem;
    // overflow: hidden;
    // opacity: 0;
    transform: translate(-50%, -50%) scale(0.25);
    transition: all 0.5s 0.2s;
    text-align: center;
  }

  &__icon--box {
    img {
      width: 13rem;
      height: 13rem;
      margin-bottom: 4rem;
    }
  }
  &__title {
    color: $theme-color-1;
    font-size: 6rem;
    margin-bottom: 6rem;
    font-family: inherit;
    font-weight: 600;
  }

  &__text {
    p {
      font-size: 3rem;
      line-height: 60px;
      color: gray;
    }
    p:not(:last-of-type) {
      margin-bottom: 8rem;
    }
    p:last-of-type {
      margin-bottom: 5rem;
    }
  }
  &__price {
    font-size: 6rem;
    font-family: inherit;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  &__percentage {
    font-size: 3rem;
    color: gray;
    margin-bottom: 5rem;
  }
  &__cta {
    width: 100%;
  }
  &__btn {
    background-color: $theme-color-1;
    padding: 3rem 30rem;
    border-radius: 10rem;
    color: #fff;
    font-size: 3rem;
    font-family: inherit;
  }

  //Open states
  // &:target {
  //   opacity: 1;
  //   visibility: visible;
  // }

  // &:target &__content {
  //   opacity: 1;
  //   transform: translate(-50%, -50%) scale(1);
  // }

  &__close {
    &:link,
    &:visited {
      color: #000;
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      font-size: 5rem;
      text-decoration: none;
      display: inline-block;
      transition: all 0.2s;
      line-height: 1;
    }

    &:hover {
      color: $theme-color-1;
    }
  }
}

.btn-submit {
  width: 100%;
  background-color: $gradient-color-1;
  color: #fff;
  border: none;
  padding: 0.7rem 0 0.5rem;
}

.btn-confirm {
  color: $theme-color-1;
  border: 1px solid $theme-color-1;

  &:hover {
    background-color: $theme-color-1;
    color: #fff;
  }
}
.btn-cancel {
  background-color: $theme-color-1;
  color: #fff;
  margin-left: 1rem;
  &:hover {
    color: #fff;
  }
}
