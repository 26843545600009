.heading-secondary {
  font-family: $font-nunito;
  font-weight: 600;
  font-size: 1.5rem;
  color: #000;
}
.heading-third {
  font-family: $font-nunito;
  font-weight: 200;
  font-size: 1.1rem;
}
.heading-main {
  font-family: $font-nunito;
  font-weight: 700;
  font-size: 1.5rem;
}
