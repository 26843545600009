//agence modal styles
.agenceInfo-modal {
  &__content {
    position: unset !important;
    max-width: 1140px !important;
    // max-height: auto;
  }
  .modal-dialog {
    position: unset !important;
    max-width: auto !important;
    transform: none !important;
  }
}
