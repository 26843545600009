.btn--outline,
.btn-inline {
  padding-bottom: 10px;
  padding-top: 10px;
}

.btn--outline {
  border: none;
  border: 1px solid $gradient-color-4;
  background-color: #ffff;
  color: $gradient-color-4;
  border-radius: 25rem;
  transition: all 0.2s ease;
  width: 40%;
  padding-bottom: 9px;
  padding-top: 9px;
  cursor: pointer;

  @media (max-width: 992px) {
    padding: 10px 25px;
  }
  @media (max-width: 575px) {
    padding: 10px 25px;
  }
  span {
    color: $gradient-color-4;
    font-size: 15px;
    font-family: $font-source-sans-pro;
  }
}
.btn--outline:hover {
  background-color: $gradient-color-4 !important;
  border: 1px solid $gradient-color-4 !important;
  span {
    color: #ffff;
  }
}
// .NavLink {
//   span {
//     color: #ffff;
//   }
// }

.form-title {
  font-size: 18px;
  font-family: $font-nunito;
  font-weight: 600;
}
.btn-box {
  display: flex;
  justify-content: space-evenly;
}

.btn-inline {
  background-color: $gradient-color-4;
  border: none;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 90%;
  @media (max-width: 992px) {
    padding: 10px 25px;
  }
  @media (max-width: 575px) {
    padding: 10px 25px;
  }
  .label {
    span {
      font-size: 15px;
      font-family: $font-source-sans-pro;
      font-weight: 400;
    }
  }
}
.inline-link {
  color: #ffff;
  font-size: 15px;
  font-family: $font-source-sans-pro;
  font-weight: 400;
}
.auth-card {
  // height: 80%;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-height: 660px) {
  .auth-card {
    height: 35rem !important;
  }
}

@media (max-width: 992px) {
  .auth-card {
    width: 80%;
    margin: 0 auto;
  }
  .auth-card .image-side {
    display: none;
  }
}

@media (max-width: 370px) {
  .auth-card {
    width: 90%;
  }
  .btn--outline {
    display: block;
  }
  .btn-inline {
    display: block;
  }
}

@media (max-width: 575px) {
  .form-side--agency {
    padding: 20px 40px !important;
  }
  .btn-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn-inline {
      margin-bottom: 20px;
      width: 90%;
    }
    .btn--outline {
      width: 90%;
    }
  }

  .password-box {
    .itemCheck {
      font-size: 10px;
    }
    .forget-password {
      span {
        font-size: 10px;
      }
    }
  }
}

.btn-box-only {
  justify-content: flex-end;
  .btn-inline {
    padding: 10px 65px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 992px) {
  .container {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .btn-box {
    flex-direction: column;
    align-items: center;
    .btn-inline,
    .btn--outline {
      margin-bottom: 20px;
      width: 90%;
    }
  }
}
@media (min-width: 1200px) {
  .btn-box {
    display: flex !important;
    flex-direction: row;
    .btn--outline {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .btn-inline,
    .btn--outline {
      margin-bottom: 0;
      width: 90%;
    }
  }
}
//custom Inputs
.custom-icon-box {
  position: relative;
  img {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    top: 12px;
    right: 5px;
    transition: 0.2s all ease;
    background-image: linear-gradient(
      rgba($gradient-color-4, 1),
      rgba($gradient-color-4, 1)
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
  img:active {
    transform: translateY(1px);
    // background-color: $gradient-color-4;
    // color: $gradient-color-4;
  }
}

//custom card title

.custom-card-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  :first-child {
    display: block;
    color: $gradient-color-4;
  }
}
.form-side--agency {
  padding: 30px 50px !important;
  width: 100% !important;
}
.input__logo--file::-webkit-file-upload-button {
  visibility: hidden;
  opacity: 0;
  display: none;
}
.input__logo--file:focus {
  outline: none;
}

.input__logo--file {
  width: 100%;
  text-align: center;
  &::before {
    content: "Inserer votre logo";
    display: inline-block;
    background-color: #f0f2f8;
    width: 100%;
    border: 2px dashed #43425d4a;
    border-radius: 3px;
    padding: 18px;
    outline: none;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    font-family: $font-nunito;
    font-weight: 500;
    font-size: 15px;
  }
}
