// h1,h2,h3,h4,h5,p{
//   display: block;
// }

.nonMapImage {
  width: 50% !important;
  background: url("/assets/img/image-signup@2x.png") no-repeat center center !important;
  background-size: cover !important;
  padding: 80px 40px !important;
}

.top-right-button-container {
  transform: translateY(-12px);
}
//dashboard styles
.heading-row {
  flex-direction: column;
}
// .dashboard-line-chart {
//   height: 440px;
// }
.ReactTable {
  .rt-table {
    overflow-x: hidden;
  }
}
.agencyPerCity-card {
  .card-body {
    .ReactTable {
      height: 372px;
    }
  }
}
.diagramme__link {
  cursor: pointer;
  &:hover {
    color: $theme-color-1;
  }
}
.diagramme__title {
  cursor: pointer;
  width: 12rem;
  padding: 0.2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $theme-color-1;
  box-shadow: 0 5px 20px rgba(#000, 0.15);
  border-radius: 10px;
  color: #fff;
  span {
    font-size: 1rem;
  }
}

// client UI styles
.feature-card {
  &:last-of-type {
    height: 191px;
  }
}
.green-card-feature {
  background-color: #02db99;
  span,
  p {
    color: #fff !important;
  }
  .image-icon {
    height: 32px;
  }

  .add-icon-card {
    transform: translateY(1.5rem);
  }
  .add-text-card {
    transform: translateY(2rem);
  }
}

.cars-card-options {
  .card-text {
    span {
      line-height: 30px;
    }
  }
  img {
    width: 32px;
    height: 32px;
    margin-bottom: 17px;
  }
  margin: 0 10px 0 0;
}

.unavailabale {
  color: $unavailabale-color !important;
}
.standby{
  color:$theme-color-2 !important;
}
.availabale {
  color: $principal-color !important;
}
.green-badge {
  color: #ffff !important;
  background-color: $principal-color !important;
}
.red-badge {
  color: #ffff !important;
  background-color: $unavailabale-color !important;
}
.yellow-badge {
  color: #ffff !important;
  background-color: $theme-color-2 !important;
}

.icon-flex-row {
  display: flex;
  justify-content: space-around;
  i {
    cursor: pointer;
    font-size: 14px;
    color: #666666;
    &:hover {
      color: $theme-color-1;
    }
  }
}
.table-card {
  border-radius: 0 !important ;
  .card-body {
    padding: 0 !important;
    .rt-thead {
      background-color: #686868;
      color: #fff;
      padding: 0 1rem;
    }
    .rt-tbody {
      padding: 0 1rem;
    }
  }
}

.feature-cards-row {
  .feature-card {
    color: #000;
    text-transform: capitalize;
    background-color: #ffff;
  }
  :nth-child(4) a .feature-card {
    color: #ffff;
    background-color: #02db99;
  }
  .col-lg-6,
  .col-xl-3 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .card {
    transition: all 0.2s ease;
    img {
      width: 40px;
      height: 40px;
    }

    .card-text {
      line-height: 40px;
    }
  }
  .card:hover {
    box-shadow: 0rem 0.5rem 2rem rgba(#000, 0.15);
  }
}

.car-image-box {
  width: 25%;
  height: auto;
  img {
    object-fit: contain;
    display: block;
    width: 100%;
  }
}
.car-parc-box {
  max-width: 100%;
  height: 7rem;
  .car-parc-picture {
    object-fit: contain;
    display: block;
    max-width: 100%;
    height: 100%;
  }
}
.parc-heading-row {
  width: 100%;
}

// .car-card-icon {
//   &:hover {
//     fill: $gradient-color-1;
//     // background-color: red;
//     // background-clip: text;
//   }
// }
.option-parc-hover {
  .a {
    transition: all 0.2s ease;
    fill: #686868;
  }
  &:hover .a {
    fill: $gradient-color-4;
  }
}

// thumb list cars style

.thumb-card-body {
  position: relative;
  .card-body {
    p {
      margin: 0;
    }

    .car-icon-box {
      .a {
        transition: 0.2s all ease;
        fill: #686868;
      }
      .car-card-icon:hover .a {
        fill: $gradient-color-3;
      }
    }
  }
}

//inserer-vehicule card style
.card-addcar {
  padding: 0rem 5rem;
  .car-column {
    max-width: 450px;
    padding-left: 4rem;
  }
  .card-body {
    display: flex;
  }
  .card-title {
    span {
      color: $unavailabale-color;
      text-transform: capitalize;
    }
  }
  &-heading {
  }
  .option-car-box {
    :not(:last-child) {
      margin-right: 0.5rem;
    }

    .btn__option-car {
      padding: 0.75rem 1.3rem 0.6rem;
      color: $gradient-color-1;
      border: 1px solid $gradient-color-1;
      background-color: #ffff;
      border-radius: 1px;
      &:hover {
        background-color: $gradient-color-1;
        color: #fff;
      }
    }
  }
  .btn-submit {
    width: 100%;
    background-color: $gradient-color-1;
    color: #fff;
    border: none;
    padding: 0.7rem 0 0.5rem;
    text-transform: uppercase;
  }

  .car-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    border: none;
    box-shadow: none;

    &__img-box {
      width: 100%;
      img {
        height: auto;
        object-fit: contain;
        width: 100%;
      }
    }
    &__name {
    }
    &__details {
      padding: 40px 40px;
      background-color: #ebebeb;
      .car-title {
        font-size: 1.6rem;
        color: #000;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
}

//agence-style
.agence-card {
  position: relative;
  .btn-edit {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    text-transform: capitalize;
  }
  .card-body {
    padding: 1rem;
  }

  &__car {
    margin-bottom: 2rem;
    .card {
      height: 100%;
    }
  }

  &__description {
  }
  &__calender {
    margin-bottom: 1rem;
  }
  &__days-of-work {
    span {
      display: inline-block;
      padding: 0.2rem 0.5rem;
      background-color: #02db99;
      color: #ffff;
      margin-right: 0.2rem;
      border: 1px solid #02db99;
      border-radius: 5px;
      margin-bottom: 0.5rem;
    }
  }
  &__contact-list {
    list-style: none;
    .contact-item {
    }
  }
  &__logo-box {
    height: 90%;
    .logo {
    }
  }
}

//pricing-table style
.pricing-row {
  :nth-child(6) {
    .pricing-card {
      background-image: linear-gradient((228deg, #02db99 0%, #03cbcb 100%));
      &__percentage,
      &__price,
      &__offre-type {
        color: #fff;
      }
      .cta-btn {
        background-color: #fff;
        color: #00dc99;
        &:hover {
          border-color: #fff !important;
          background-color: #fff !important;
        }
      }
    }
  }
  .padd-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pricing-card {
    &__price,
    &__offre-type,
    .cta-btn {
      font-family: $font-nunito;
      font-weight: 600;
    }
    &__logo-box {
      width: 100%;
      // margin-bottom: 3rem;
    }
    &__logo {
      height: 50px;
      width: 50px;
      object-fit: cover;
      background-size: cover;
    }

    &__price {
      color: #000;
      font-size: 1.8rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &__percentage {
    }
    &__offre-type {
      color: #00dc99;
      text-transform: capitalize;
      font-size: 1rem;
    }
    .cta-btn {
      font-size: 0.8rem !important;
    }
  }
}

//template page style

.template-card {
  position: relative;
  width: 100%;

  &__overlay {
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(#000, 0.7), rgba(#000, 0.7));
    opacity: 0;
    top: 0;
    transition: opacity 0.2s ease;
  }
  &:hover &__overlay {
    visibility: visible;
    display: block;
    opacity: 0.7;
  }
  &:hover &__btn {
    display: block;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s all ease;
    z-index: 100;
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .card-addcar {
    .card-body {
      display: flex;
      justify-content: center;
    }
    .car-column {
      width: 500px;
    }
  }
}

//calendar-styles
.calendar-row {
  margin-left: 0px;
  margin-right: 0px;
}

// calendar-pop-up styles
.calendar-modal {
  .modal-content {
    border-radius: 0.75rem;
  }
  .modal-footer {
    border: none !important;
  }
  &__card {
    position: relative;
    box-shadow: none !important;
    .card-body {
      padding: 2rem;
    }
  }
  &__close {
    // width: 10rem;
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  &__header {
    border: none !important;
    margin: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .modal-heading {
      color: $gradient-color-4;
      font-family: $font-nunito;
      font-weight: 700;
      font-size: 2rem;
    }
    img {
      display: block;
      margin-bottom: 1rem;
    }
  }
  &__button-box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.modal-backdrop {
  background-color: $gradient-color-4;
  &.show {
    opacity: 0.1 !important;
  }
}

@media only screen and (max-width: 490px) {
  .thumb-card-body {
    .custom-switch {
      position: relative;
      transition: all 0.1s ease-out;
      transform: translate(1rem, -1rem);
    }
  }
}
@media only screen and (min-width: 991px) {
}
@media only screen and (max-width: 991px) {
  .thumb-card-body {
    position: relative;
    .custom-switch {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    .mg-bm-4 {
      margin-bottom: 1.5rem !important;
    }
    .mg-bm-3 {
      margin-bottom: 0.8rem !important;
    }
    .mg-bm-2 {
      margin-bottom: 0.5rem !important;
    }

    .car-icon-box {
      padding-left: 8px;
      .a {
        fill: #686868;
        &:hover {
          fill: $gradient-color-3;
        }
      }
    }
  }
}

.label-input-file {
  width: 110px !important;
  height: 110px;
  border-radius: 55px;
  background-color: #fcfcfd;
  border: 2px dashed #43425d4a;
  text-align: center;
  line-height: 120px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  i {
    font-size: 30px;
    color: #43425d;
  }
}

.standby-switch {
  display: block !important;
  background: #de9c04 !important;
  width: 58px !important;
  height: 27px !important;
  border: 1px solid #d6d6d6 !important;
}

.ficheClient {
  background-color: $theme-color-1;
  color: #fff;
  flex-direction: row;
  &--comments {
    list-style-type: none;
  }
  &--comment {
    i {
      color: $theme-color-1;
      padding-right: 5px;
    }
  }
  &-option--card {
    .card-body {
      padding: 1.5rem 1.2rem;
    }
    &.bg-gray {
      background-color: #43425d;
    }
    &.bg-pink {
      background-color: #e84271;
    }
    &.bg-green {
      background-color: $theme-color-1;
    }
    &.bg-black {
      background-color: #000;
    }
    color: #fff;
    .add-icon-box {
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;
      background-color: #fff;
      border-radius: 30%;
      width: 1.2rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .plus-icon {
        fill: $theme-color-1;
        width: 0.8rem;
        height: 0.8rem;
        color: #fff;
      }
    }
  }
  .list-item-heading {
    font-size: 0.7rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  &-option-list {
    list-style-type: none;
  }
  &-option-item {
    i {
      color: #fff;
      padding-right: 5px;
    }
  }

  .list-item-text {
    font-size: 1rem;
  }
  .logo-box {
    padding: 1.75rem;
  }
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
}

.ficheClientStandBy {
  background-color: #de9c04;
  color: #fff;
  flex-direction: row;
  &--comments {
    list-style-type: none;
  }
  &--comment {
    i {
      color: $theme-color-1;
      padding-right: 5px;
    }
  }
  &-option--card {
    .card-body {
      padding: 1.5rem 1.2rem;
    }
    &.bg-gray {
      background-color: #43425d;
    }
    &.bg-pink {
      background-color: #e84271;
    }
    &.bg-green {
      background-color: $theme-color-1;
    }
    &.bg-black {
      background-color: #000;
    }
    color: #fff;
    .add-icon-box {
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;
      background-color: #fff;
      border-radius: 30%;
      width: 1.2rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .plus-icon {
        fill: $theme-color-1;
        width: 0.8rem;
        height: 0.8rem;
        color: #fff;
      }
    }
  }
  .list-item-heading {
    font-size: 0.7rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  &-option-list {
    list-style-type: none;
  }
  &-option-item {
    i {
      color: #fff;
      padding-right: 5px;
    }
  }

  .list-item-text {
    font-size: 1rem;
  }
  .logo-box {
    padding: 1.75rem;
  }
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
}
