.mg-bm-large {
  margin: 30px 0 100px;
}
.mg-bm-medium {
  margin: 30px 0 44px;
}
.mg-bm-small {
  margin: 30px 0 18px;
}
.mg-bm-xlg {
  margin: 30px 0 200px;
}
.mt-6 {
  margin-top: 2rem;
}
.md-width{
  width: 40%;
}
.hover-red{
  &:hover{
    color: #e84271;

  }
}
.label-customize-file{
  width: 100% !important;
  height: auto;
  border-radius: 20px;
  background-color: #fcfcfd;
  border: 2px dashed #43425d4a;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.badge{
  font-size: .7rem;
}
.notification{
  box-shadow: none !important;
}

